<template>
  <Layout class="LuckyDrawManagement">
    <div
      v-loading="statisticsLoading"
      class="overview"
    >
      <div class="items">
        <div class="name">
          累计上线期数
        </div>
        <div class="num">
          {{ statisticsInfo.cumulativeBonusPeriod | numberFilter }}  <span class="unit">期</span>
        </div>
      </div>
      <div class="items">
        <div class="name">
          累计销售
        </div>
        <div class="num">
          {{ statisticsInfo.cumulativeGoldCoins | numberFilter }} <span class="unit">coins</span>
        </div>
      </div>
      <div class="items">
        <div class="name">
          正在进行的期数
        </div>
        <div class="num">
          {{ statisticsInfo.interimPeriod | numberFilter }} <span class="unit">期</span>
        </div>
      </div>
      <div class="items">
        <div class="name">
          待发货的期数
        </div>
        <div class="num">
          {{ statisticsInfo.deliveryPeriod | numberFilter }} <span class="unit">期</span>
        </div>
      </div>
    </div>
    <el-form :model="formData">
      <el-form-item>
        <el-button
          type="primary"
          @click="drawerVisible = true"
        >
          新开奖期
        </el-button>
      </el-form-item>
    </el-form>
    <el-table
      v-loading="listInfo.loading"
      :data="listInfo.list"
      stripe
      style="width: 100%"
    >
      <el-table-column
        label="期号"
        prop="id"
        width="120px"
      />
      <el-table-column
        label="本期商品"
        prop="prizeName"
        width="170px"
      />
      <el-table-column
        label="中奖号码"
        prop="winCodeNum"
        width="120px"
      />
      <el-table-column
        label="中奖用户ID"
        prop="winUserIdStr"
        width="180px"
      />
      <el-table-column
        label="是否机器人"
        prop="winUserIdStr"
        width="180px"
      >
        <template #default="{row}">
          {{ row.isRobot ? '是' : '否' }}
        </template>
      </el-table-column>
      <el-table-column
        label="设定参与人次"
        prop="setupPersonTimes"
        width="120px"
      />
      <el-table-column
        label="实际参与人次"
        prop="actualPersonTimes"
        width="120px"
      />
      <el-table-column
        label="实际参与人数"
        prop="actualParticipants"
        width="180px"
      />
      <el-table-column
        label="上线时间"
        prop="createTime"
        width="160px"
      />
      <el-table-column
        label="机器人人次占比"
        width="160px"
      >
        <template v-slot="{row}">
          {{ (((row.robotPersonTimes / Math.max(row.actualPersonTimes, row.setupPersonTimes)) || 0 ) * 100).toFixed(2) + '%' }}
        </template>
      </el-table-column>
      <el-table-column
        label="进度完成用时(分钟）"
        prop="progressTime"
        width="160px"
      />
      <el-table-column
        label="状态"
        prop="activityStatus"
        width="160px"
      >
        <template v-slot="{row}">
          <i
            class="status-dot"
            :class="{
              normal: row.activityStatus === 1,
              success: row.activityStatus === 5,
              warn: row.activityStatus === 3,
              failed: row.activityStatus === 4
            }"
          />
          {{ statusMap[row.activityStatus] || '/' }}
        </template>
      </el-table-column>
    </el-table>
    <Pagination
      :total="listInfo.total"
      :page.sync="formData.pageNum"
      :limit.sync="formData.pageSize"
      @pagination="queryDataList"
    />
    <el-drawer
      title="新建奖期"
      :visible.sync="drawerVisible"
      direction="rtl"
      size="400px"
    >
      <el-form
        ref="ruleForm"
        :model="newLuckyDrawForm"
        :rules="rules"
        label-width="80px"
      >
        <!-- <el-form-item label="奖期">
          123134435555
        </el-form-item> -->
        <el-form-item
          prop="prizeId"
          label="商品"
        >
          <el-select
            v-model="newLuckyDrawForm.prizeId"
            filterable
          >
            <el-option
              v-for="(it, ind) in prizeList"
              :key="ind"
              :label="it.prizeTitle"
              :value="it.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item
          prop="setupPersonTimes"
          label="参与人次"
        >
          <el-input-number
            v-model="newLuckyDrawForm.setupPersonTimes"
            :min="1"
            :step="1"
            step-strictly
          />
        </el-form-item>
      </el-form>
      <div style="text-align:right;">
        <el-button
          @click="drawerVisible=false"
        >
          取消
        </el-button>
        <el-button
          type="primary"
          :loading="editLoading"
          @click="handleCreateLuckyDraw"
        >
          确认
        </el-button>
      </div>
    </el-drawer>
  </Layout>
</template>

<script>
import {
  getOneDollarPrizeList,
  createOneDollarActivity,
  getOneDollarActivityList,
  oneDollarActivityStatistics
} from '@/api/LuckyBuy.js'

export default {
  filters: {
    numberFilter (num) {
      if (num === undefined || num === null) {
        return '/'
      }
      return num
    }
  },
  data () {
    return {
      drawerVisible: false,
      statisticsInfo: {},
      statisticsLoading: false,
      statusMap: {
        1: '进行中',
        2: '待开奖中',
        3: '待确认地址',
        4: '待发货',
        5: '已完成'
      },
      formData: {
        pageNum: 1,
        pageSize: 20
      },
      listInfo: {
        list: [],
        total: 0,
        loading: false
      },
      editLoading: false,
      newLuckyDrawForm: {},
      rules: {
        prizeId: [
          { required: true, message: '必须选择商品' }
        ],
        setupPersonTimes: [
          { required: true, message: '参与人数必填' }
        ]
      },
      prizeList: []
    }
  },
  created () {
    this.oneDollarActivityStatistics()
    this.queryDataList()
    this.queryPrize()
  },
  methods: {
    oneDollarActivityStatistics () {
      this.statisticsLoading = true
      oneDollarActivityStatistics({})
        .then(res => {
          if (res.code === 200) {
            this.statisticsInfo = res.data
          }
        })
        .finally(() => {
          this.statisticsLoading = false
        })
    },
    handleSearch () {
      this.formData.pageNum = 1
      this.queryDataList()
    },
    queryDataList () {
      const formData = this.formData
      this.listInfo.loading = true
      getOneDollarActivityList(formData)
        .then(res => {
          if (res.code === 200) {
            this.listInfo.list = res.data.list || []
            this.listInfo.total = res.data.total || 0
          }
        })
        .finally(() => {
          this.listInfo.loading = false
        })
    },
    queryPrize () {
      getOneDollarPrizeList({
        pageNum: 1,
        pageSize: 5000
      })
        .then(res => {
          if (res.code === 200) {
            this.prizeList = res.data.list || []
          }
        })
    },
    handleCreateLuckyDraw () {
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          this.editLoading = true
          createOneDollarActivity({
            ...this.newLuckyDrawForm
          })
            .then(res => {
              if (res.code === 200) {
                this.$message.success('奖期添加成功')
                this.drawerVisible = false
                this.newLuckyDrawForm = {}
                this.$refs.ruleForm.resetFields()
                this.handleSearch()
              }
            })
            .finally(() => {
              this.editLoading = false
            })
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
  .LuckyDrawManagement {
    .overview {
      display: flex;
      // padding: 0 0 20px 0;
      min-width: 880px;
      margin-bottom: 10px;
      .items {
        width: 210px;
        height: 100px;
        box-shadow: 0 0 2px 1px rgba(0, 0, 0, .12);
        border-radius: 4px;
        margin-right: 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .num {
          font-size: 26px;
          margin-bottom: 10px;
          .unit {
            font-size: 14px;
          }
        }
        .name {
          font-size: 12px;
        }
      }
    }
    .status-dot {
      display: inline-block;
      width: 8px;
      height: 8px;
      border-radius: 8px;
      background: gray;
      margin-right: 10px;
      &.normal{
        background: #198FFC;
      }
      &.success{
        background: #51C41B;
      }
      &.warn{
        background: #FF9800;
      }
      &.failed{
        background: #FF5C61;
      }
    }
  }
</style>
